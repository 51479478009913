<template>
  <v-flex
    xs12
    sm12
    md12
    style="padding: 1%"
    v-if="!isConnected && !whatsappRegistered"
  >
    <WhatsappConnector
      :is-connected="isConnected"
      :whatsapp-registered="whatsappRegistered"
      :set-chat-show="setChatShow"
    />
  </v-flex>

  <v-card
    fluid
    flat
    tile
    min-height="92vh"
    class="back mb-n15 pa-6 px-md-15"
    v-else
  >
    <v-card-text v-for="list in lists" :key="list.id" class="">
      <h3>{{ list.name }}</h3>
      <automation-rules
        :list="list"
        :all-lists="lists"
        :pageName="pageName"
        @refresh="refresh()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import automationRules from "../../components/automationRules.vue";
import WhatsappConnector from "../../components/whatsappConnector.vue";
const _ = require("lodash");
export default {
  components: { automationRules, WhatsappConnector },
  data: () => ({
    isConnected: false,
    whatsappRegistered: false,
    lists: [],
    rules: [],
    pageName: "Whatsapp_Automation",
  }),
  computed: {
    ...mapGetters([
      "ENDPOINT",
      "dispoList",
      "teams",
      "user",
      "selectedTeam",
      "selectedList",
    ]),
  },
  async created() {
    const getIntegration = await this.$http.get(
      `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp`
    );
    console.log("integrations details: ", getIntegration.body);
    if (
      getIntegration.body.length &&
      getIntegration.body[0].config.initializeDone
    ) {
      this.setChatShow();
      await this.fetchLists();
    }

    // this.getRules();
  },
  methods: {
    setChatShow() {
      this.isConnected = true;
      this.whatsappRegistered = true;
    },
    // async getRules(listId) {
    //     var res = await this.$http.get(
    //         `${this.ENDPOINT}/lists/${listId}?filter[fields][triggers]=true`
    //     );
    //     this.rules = res.body.triggers || [];
    //     console.log("Rules: ",this.rules);
    //     return this.rules;
    //     },
    async refresh() {
      console.log("Refresh in automation Parent");
      await this.fetchLists();
    },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }
      teams = _.without(teams, "allTeams");
      return teams;
    },
    async fetchLists() {
      this.lists = [];
      var teamIds = await this.getTeamIds();
      await this.$http
        .post(`${this.ENDPOINT}/lists/fetch`, { teams: teamIds })
        .then((res) => {
          this.lists = res.body;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style></style>
